import { useEffect } from 'react';

export default () => {
    useEffect(() => {
        if (typeof window !== 'undefined')
            window.location.replace('https://itunes.apple.com/mx/app/cuenca/id1447995379?mt=8');
    }, []);

    return null;
};
